.RamadanTime {
	height: 55vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 0 7.5vw;
}

.ramadan_time_wrapper {
	width: 100%;
}

.time_wrapper {
	height: 60vh;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.time_container {
	/* height: 55vh; */
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
	width: 100%;
	/* margin-bottom     : .5rem; */
}

.time_left {
	display: flex;
	flex-direction: column;
	gap: 4vh;
}

/* box styles */
.box_heading {
	/* font-family: 'Outfit'; */
	font-style: normal;
	font-weight: 700;
	font-size: 20px;
	/* line-height   : 20px; */
	text-align: center;
	color: #99bed7;
	/* padding-bottom: 1.8rem; */
}

/* for location and language */
.location_language {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 7.5vw;
}

.day_overview {
	/* font-family: 'Outfit'; */
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 20px;
	text-align: center;
	color: #99bed7;
	opacity: 0.5;
}
