.nav_footer {
    height       : 10vh;
    display      : flex;
    align-items  : center;
    background   : rgba(0, 0, 0, .15);
    border-radius: 20px 20px 0px 0px;
    padding      : 14px 24px;
}

.nav_footer_items {
    width          : 100%;
    height         : 100%;
    display        : flex;
    align-items    : center;
    justify-content: space-between;
}

.nav_footer_item {
    display      : flex;
    align-items  : center;
    padding      : 10px 20px;
    border-radius: 10px;
    /* transition   : all .8s ease-in-out; */
}

.nav_footer_item.active {
    background-color: #0b4062;
    border          : 1px solid #EABA22;
}

.nav_footer_item img {
    width  : 24px;
    opacity: .7;
}

.nav_footer_item span {
    /* font-family       : 'Outfit'; */
    font-style        : normal;
    font-weight       : 500;
    font-size         : 12px;
    color             : #EABA22;
    text-align        : right;
    margin-left    : 1rem;
    width             : 0;
    overflow          : hidden;
}

.nav_footer_item.active>span {
    width      : auto;
    overflow: visible;
}