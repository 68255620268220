.large_view{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.large_view img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
@media (orientation: landscape) {
    .large_view{
        display: block;
    }
}