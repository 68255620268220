.seheri_and_iftar_box {
    height            : 26vh;
    width             : 100%;
    background        : #0B4062;
    border            : 1px solid #1F6D9F;
    border-radius     : 30px;
    display           : flex;
    /* flex-direction : column; */
    /* justify-content: center;  */
    align-items       : center;
}

.seheri_and_iftar_box .container {
    /* background-color: rebeccapurple; */
    height         : 20vh;
    width          : 100%;
    display        : flex;
    flex-direction : column;
    justify-content: space-between;
}

.box_date {
    /* font-family: 'Outfit'; */
    font-style : normal;
    font-weight: 600;
    font-size  : 12px;
    line-height: 15px;
    text-align : center;
    color      : #99BED7;
}

.box_time {
    /* font-family: 'Outfit'; */
    font-style : normal;
    font-weight: 400;
    font-size  : 12px;
    line-height: 15px;
    text-align : center;
    color      : #FFFFFF;
}

.box_time>span {
    font-family: 'Outfit';
}