.btn_location {
    display: flex;
    align-items: center;
    background   : rgba(11, 64, 98, 0.5);
    border       : 1px solid #1F6D9F;
    border-radius: 50px;
    padding      : 9px 15px;
}

.btn_location span {
    /* font-family: 'Outfit'; */
    font-weight: 300;
    font-size  : 12px;
    line-height: 20px;
    text-align : center;
    color      : #99BED7;
    margin-right: 5px;
}