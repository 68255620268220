table {
	outline: 1px solid #99bed7;
	border-collapse: collapse;
}

td {
	border: 1px solid #99bed7;
}

th {
	padding: 0.8rem 0.5rem;
}

.table_heading {
	height: 4vh;
}

.table_heading th:nth-child(2) {
	text-align: right;
	padding-right: 20px;
}

.table_heading th img {
	transform: rotate(-180deg);
}

.calender_table.active-table .table_heading th img {
	transform: rotate(0deg);
}

table {
	background: #0b4062;
	border-radius: 20px;
}

table:not(:last-child) {
	margin-bottom: 2vh;
}

.calender {
	height: 55vh;
	overflow: auto;
	padding: 1rem 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.calender_header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	background: #0b4062;
	border-radius: 20px;
	padding: 2rem;
}

.calender_content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.calender_table {
	width: 100%;
}

.active-table {
	border-radius: 20px;
}

.table_title {
	/* font-family: 'Outfit'; */
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 20px;
	text-align: left;
	color: #99bed7;
	padding-left: 30px;
}

.calender_table.active-table .table_title {
	color: #eaba22;
}

.table_sub_heading th {
	border: 1px solid #99bed7;
}

.calender_table_body td {
	/* font-family: 'Outfit'; */
	font-style: normal;
	font-weight: 400;
	font-size: 11px;
	line-height: 20px;
	text-align: center;
	padding: 0.3rem 1rem;
	color: #99bed7;
}

table tr td:first-child {
	border-left: none;
}

table tr td:last-child {
	border-right: none;
}

table tr:last-child td {
	border-bottom: none;
}

.todays_date td {
	border: 1px solid #e0d6b5;
}

.thead-center {
	text-align: center;
	padding-right: 30px;
}
