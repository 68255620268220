.header-container {
	position: relative;
	overflow: hidden;
	height: 20vh;
}
.header-container > svg {
	width: 100%;
	height: auto;
}
.text-overlay {
	width: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	font-style: normal;
	font-weight: 700;
	font-size: 3vh;
	line-height: 48px;
	/* font-family: 'Outfit', sans-serif; */
	color: white;
	padding: 0 7.5vw;
}
