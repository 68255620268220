.language {
    background   : rgba(11, 64, 98, 0.5);
    border       : 1px solid #1F6D9F;
    border-radius: 50px;
}

.language button {
    width        : 26px;
    height       : 26px;
    border-radius: 50%;
    margin: 1px;
}

.language button.active {
    background: #99BED7;
    opacity   : 0.8;
}

.language button span {
    /* font-family: 'Outfit'; */
    font-weight: 400;
    font-size  : 12px;
    line-height: 20px;
    text-align : center;
    color      : #99BED7;
}

.language button.active span {
    color: #333;
}