.loading_max {
	height: 55vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #09314b;
}

.loading_max > div {
	height: 35vh;
}
