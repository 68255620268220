.modal {
    z-index: 1060;
}

.modal,
.modal_backdrop {
    position: fixed;
    top     : 0;
    left    : 0;
    width   : 100%;
    height  : 100%;
}

.modal_backdrop {
    background-color: rgba(0, 0, 0, .7);
}

.modal_dialog {
    position      : relative;
    max-width     : 114rem;
    margin        : 1.75rem auto;
    pointer-events: none;
}

.modal_dialog_scrollable {
    height: calc(100% - 3.5rem);
}

.modal_dialog_centered {
    display    : flex;
    align-items: center;
    min-height : calc(100% - 3.5rem);
}

.modal_content {
    position        : relative;
    width           : 100%;
    display         : flex;
    flex-direction  : column;
    background-color: #09314B;
    border-radius   : 2.4rem;
    pointer-events  : auto;
    padding         : 3rem 2.4rem;
    z-index         : 2;
}

.modal_dialog_scrollable .modal_content {
    max-height: 100%;
    overflow  : hidden;
}

.modal_dialog_scrollable .modal_body::-webkit-scrollbar {
    display: none;
}

.modal_dialog_scrollable .modal_body {
    overflow-y        : auto;
    -ms-overflow-style: none;
    scrollbar-width   : none;
}



.modal_close {
    position : absolute;
    top      : 2.6rem;
    right    : 2.4rem;
    z-index: 2;
}

.modal_heading {
    font-family: 'Outfit';
    font-weight: 400;
    font-size  : 18px;
    line-height: 20px;
    text-align: center;
    color: #99BED7;
    opacity: 0.5;
}

@media screen and (max-width: 767.98px) {
    .modal_dialog {
        margin: 1.75rem 1rem;
    }
}