.dua_table_body td {
	padding: 22px 22px;
}

.dua_table_body p:not(:last-of-type) {
	margin-bottom: 20px;
}
.dua_table_body p {
	/* font-family: 'Outfit'; */
	/* font-family: 'Tiro Bangla', serif; */
	font-weight: 400;
	font-size: 11px;
	line-height: 24px;
	text-align: center;
	/* color: #99BED7; */
	color: #fff;
}

.dua_table_body p.arabic {
	font-family: 'Noto Naskh Arabic', serif;
	font-size: 11px;
	font-weight: 500;
	color: #eaba22;
}

.dua_table_body p span {
	/* color: #fff; */
	color: #99bed7;
}

.coming_soon {
	color: #99bed7;
	font-family: 'Outfit';
}
