@import url('https://fonts.googleapis.com/css2?family=Noto+Naskh+Arabic:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tiro+Bangla:ital@0;1&display=swap');

@import url('https://fonts.maateen.me/mukti/font.css');

body {
	background-color: #09314b;
}

main {
	height         : 100vh;
	display        : flex;
	flex-direction : column;
	justify-content: space-between;
}

.nav-icons {
	width : 8vw;
	height: 8vh;
	fill  : #99bed7;
}

main {
	font-family: 'Outfit';
}
main.bn {
	font-family: 'Mukti' !important;
}
