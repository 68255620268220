.prayer-time {
	height         : 56vh;
	background     : #0b4062;
	border         : 1px solid #1f6d9f;
	border-radius  : 30px;
	display        : flex;
	justify-content: center;
	align-items    : center;
}

.prayer-time .container {
	height         : 50vh;
	display        : flex;
	flex-direction : column;
	justify-content: space-between;
	overflow       : hidden;
}

.time {
	color: #eaba22;
}

.time>span {
	font-family: 'Outfit';
}

.daily-prayer {
	margin         : 0.5rem 0;
	display        : flex;
	align-items    : center;
	justify-content: space-between;
	flex-direction : column;
	gap            : 1px;
}

.active-prayer {
	display      : block;
	background   : #0d4e77;
	border-radius: 20px;
	padding      : 2vh;
	margin       : 1vh 0;
}

.daily-prayer-time {
	width          : 100%;
	display        : flex;
	align-items    : center;
	justify-content: space-between;
	/* font-family : 'Outfit'; */
	font-style     : normal;
	font-weight    : 500;
	font-size      : 12px;
	line-height    : 20px;
	text-align     : center;
	color          : #ffffff;
}

.daily-prayer-done {
	opacity: 0.6;
}

.dashed-border {
	border-top: dashed 1px #86c2e8;
}

.py-border {
	margin: 0.5rem 0;
}