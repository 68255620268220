.division_lists .calender {
    padding: 2rem .2rem 0;
    align-items    : flex-start;
}

.division_lists .calender_container {
    width: 100%;
}

.division_table_body td {
    padding-bottom: 2.4rem;
}

.division_table_body td>div {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    padding        : 1.5rem 1.5rem 0;
}

.division_table_body td>div span {
    display: inline-block;
}

.division_table_body td>div span.text {
    font-weight: 400;
    font-size  : 12px;
    line-height: 17px;
    text-align : center;
    color: #99BED7;
}

.division_table_body td>div span.radio {
    width        : 1.4rem;
    height       : 1.4rem;
    border       : 1px solid #99BED7;
    border-radius: 50%;
    position     : relative;
}

.division_table_body td>div span.radio::before {
    content         : '';
    position        : absolute;
    width           : .8rem;
    height          : .8rem;
    top             : 50%;
    left            : 50%;
    transform       : translate(-50%, -50%);
    border-radius   : 50%;
    background-color: transparent;
}

.division_table_body td>div.active span.text {
    color: #EABA22;
}

.division_table_body td>div.active span.radio {
    border       : 1px solid #EABA22;
}

.division_table_body td>div.active span.radio::before {
    background-color: #EABA22;
}