.remaining_time {
    /* font-family: 'Outfit'; */
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 29px;
    text-align: center;
    color: #EABA22;
}

.remaining_text {
    /* font-family: 'Outfit'; */
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #5E91B4;
}